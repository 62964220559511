//Márgenes
.mt-15 {
    margin-top: 15rem!important;
}
.mt-12 {
    margin-top: 12rem!important;
}
.mt-10 {
    margin-top: 10rem!important;
}
.mt-8 {
    margin-top: 8rem!important;
}
.mt-5 {
    margin-top: 5rem!important;
}
.mt-4 {
    margin-top: 4rem!important;
}
.mt-3 {
    margin-top: 3rem!important;
}
.mt-2 {
    margin-top: 2rem!important;
}
.mt-1 {
    margin-top: 1rem!important;
}

.mb-1 {
    margin-bottom: 1rem!important;
}
.mb-2 {
    margin-bottom: 2rem!important;
}
.mb-3 {
    margin-bottom: 3rem!important;
}
.mb-5 {
    margin-bottom: 5rem!important;
}
.mb-8 {
    margin-bottom: 8rem!important;
}

//Paddings
.p-1 {
    padding: 1rem!important;
}
.p-2 {
    padding: 2rem!important;
}
.p-3 {
    padding: 3rem!important;
}
.p-4 {
    padding: 4rem!important;
}


//Flexbox
.flex-center-y {
    display: flex;
    align-items: center;
}
.flex-center-x {
    display: flex;
    justify-content: center;
}

.flex-end-x {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}