@import "../../styles/main.scss";

.nav-bar {
  background-color: $grayfooter !important;
  .toolbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    .logo {
      height: 3rem;
      width: 9rem;
      margin-bottom: 0.4rem !important;
      margin-top: 0.4rem !important;
    }
    .actions-container {
      color: black;
      display: flex;
      .text-username {
        color: $black;
      }
      .btn-menu {
        margin-right: 2rem !important;
        margin-bottom: 1rem !important;
        margin-top: 1rem !important;
      }
      .icon-menu {
        font-size: 35px !important;
      }
      a.item-menu {
        font-size: 18px !important;
        color: white;
        padding-left: 35px !important;
        padding-right: 35px !important;
        text-decoration: none;
      }
      a.item-menu:hover {
        color: $orange;
        cursor: pointer !important;
      }
      a.item-menu.active {
        background-color: $orange;
        border-radius: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      a.item-menu.active:hover {
        color: white;
      }
    }
  }
}

@media (max-width: 768px) {
  .nav-bar {
    .toolbar {
      padding: 0 1rem;
      .logo {
        height: 2rem;
        width: 6rem;
      }
      .actions-container {
        .btn-menu {
          margin-right: 0rem !important;
          margin-bottom: 0.5rem !important;
          margin-top: 0.5rem !important;
        }
        .icon-menu {
          font-size: 30px !important;
        }
      }
    }
  }
}
