
@import 'utils';
@import 'variables';

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  opacity: 0.2;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:10px;
  opacity: 0.2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
  opacity: 0.2;
}

body, 
.body-container {
  background-color: #e8e8e8!important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input.form-control,
textarea.form-control {
  border-radius: 25px !important;
}

.btn-main-menu {
  background: #2a7c81 0% 0% no-repeat padding-box;
  color: #ffffff;
  opacity: 0.5;
  border-radius: 40px;
}

.main-menu {
  position: fixed;
  top: 0;
  width: 100%;
}

.btn-main-menu:hover {
  background: #f69e28 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 40px;
}

.btn-main {
  border-radius: 40px !important;
	text-transform: none !important;
}

.main-h1 {
  font: normal normal bold 38px/60px;
  letter-spacing: 0.38px;
  color: #2a7c81;
}

.exp-card {
  max-width: 380px;
  max-height: 280px; 
  height: 280px; 
  border-radius: 25px;
}

.exp-card-main-img {
  background: #f69e28 0% 0% no-repeat padding-box;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.exp-card-secondary-img {
  max-height: 50px;
}
.serv-card {
  max-width: 265px;
  border-radius: 25px;
  min-height: 420px;
  max-height: 420px;
  margin: 25px;
}

.footer {
  /* position: fixed;
  bottom: 0; */
  width: 100%;
}
.footer-bg1 {
  background: #313030 0% 0% no-repeat padding-box;
}
.footer-bg2 {
  background: #1c1c1c 0% 0% no-repeat padding-box;
}

.footer-title {
  color:$yellow !important;
  font-weight: $bold !important;
  font-size: 14px !important;
}

.footer-text {
  color:white !important;
  font-weight: $semibold !important;
  font-size: 12px !important;
  padding: 3px !important;
}

a.footer-text{
  text-decoration: none;
}

.footer-subtext {
  font-size: 12px !important;
  color:white !important;
}

.aliado-img {
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}

.text-contacto {
  min-height: 200px;
  height: 200px;
  max-height: 200px;
}
.footer-follow{
  float: left;
}
.footer-follow ul li {
  display:inline-block;
  list-style-type: none;
  margin-left: 10px;
}

/**************** nuevos **************/

.main-container {
	min-height: calc(80vh - 48px);
}

.text-main-titte{
	font-size: 40px !important;
}

.background-image{
	background-image:url('./../../src/resources/cover.png');
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
	background-size:cover;
	height: 900px;
}

.background-image-scrum{
	background-image:url('./../../src/resources/scrum-trabajo.png');
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
	background-size:cover;
	height: 800px;
}

.paper-style{
	padding: 30px !important;
  align-items: center !important;
  justify-content: center !important;
}

.card-description{
  font-size: 14px !important;
}

.card-title{
  font-size: 14px !important;
}

.img-logo {
  height: 3rem;
  width: 9rem;
}

.icon-contact{
  font-size: 14px !important;
  color: white;
  margin-right: 10px !important;
  margin-bottom: -3px;
}

.icon-social{
  font-size: 24px !important;
  color: $yellow;
}

.textField{
  background-color: white;
  border-radius: 10px !important;
  color: black !important;
  font-weight: $semibold !important;
}

.img-card-exp{
  display: block;
}

.blog-label{
  font-size: 36px !important;
  color: $orange;
  font-weight: $bold !important;
}

.blog-title{
  font-size: 46px !important;
  color: $green;
  font-weight: $bold !important;
}

.blog-subtitle{
  font-size: 24px !important;
}

.blog-image{
  height: 20rem;
  width: 30rem;
}

@media (max-width: 768px) {
	.background-image{
		background-image:url('./../../src/resources/cover-movil.png');
		height: 650px;
	}
  .background-image-scrum{
		background-image:url('./../../src/resources/scrum-trabajo-movil.png');
		height: 650px !important;
	}
	.text-main-titte{
		font-size: 18px !important;
	}
  .card-description{
    font-size: 12px !important;
  }
  .card-title{
    font-size: 12px !important;
  }
  .footer-title {
    font-size: 12px !important;
  }
  .footer-text {
    font-size: 10px !important;
  }
  .footer-subtext {
    font-size: 8px !important;
  }
  .icon-social{
    font-size: 19px !important;
  }
 /*  .img-card-exp{
    display: none!important;
  }; */
  .aliado-img {
    min-width: 90px;
    max-width: 90px;
    width: 90px;
  }
  .exp-card-secondary-img {
    max-height: 40px;
  }
  .paper-style{
    padding: 20px !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .blog-title{
    font-size: 14px !important;
  }
  .blog-subtitle{
    font-size: 10px !important;
  }
  .blog-image{
    height: 6rem;
    width: 9rem;
  }
  .blog-label{
    font-size: 14px !important;
  }
  
}